<template>
  <div>
    <dynamicComponent v-for="type in result['template']" :key="type" :type="type" :result="result" v-on="$listeners"></dynamicComponent>
  </div>
</template>
<script>
  import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
  export default {
    components: {
      dynamicComponent
    },
    data: function() {
      return {
        sentConfig: this.result.json
      };
    },
    props: { result: Object }
  };
</script>
<style>
  .w100 {
    width: 100%;
  }
</style>
